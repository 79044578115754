import React from "react"

import MainLayout from "../components/layouts/BlogLayout"
import SEO from "../components/seo"
import PoliticaDePrivacidad from "../legal/politica-de-privacidad.mdx"

const NotFoundPage = () => (
  <MainLayout>
    <SEO title="Politica de privacidad" />
    <PoliticaDePrivacidad />
  </MainLayout>
)

export default NotFoundPage
